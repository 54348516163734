import React from 'react'
import { PageProps } from 'gatsby'
import { Enterprise as EnterpriseWrapper } from '~/components'

const Enterprise = (props: PageProps & { '*'?: string }) => {
  const slug =
    (props.pageContext as { enterprise?: { slug: string } }).enterprise?.slug ||
    props['*'] ||
    '-'

  return <EnterpriseWrapper slug={slug} {...props} />
}

export default Enterprise
